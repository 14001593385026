.container{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 100px;
}

.h4{
    font-size: 17px;
    color:#f3530e;
    margin-bottom: 20px;
}

.project{
    margin-bottom:50px;
}

.toolHeading{
    margin-bottom: 20px;
    font-size: 17px;
}

.toolHeadingColor{
    color:cadetblue;
    text-decoration: none;
}

.toolHeadingColor:hover{
    color:#f3530e;
}

.aboutText{
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 20px;
}

.toolName{
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    padding: 7px 12px;
    border-radius: 50px;
    font-weight: 400;
    border: 1px solid cadetblue;
}

.projectImg{
    width:100px;
    border-radius: 10px;
    border:1px solid rgb(35, 35, 35);
    margin:10px 0px 15px 0px;
}
.projectImg:hover{
    opacity: 0.7;
    border:1px solid #f3530e;
}

.icons{
    margin-top: 10px;
    font-size: 23px;
}

.icon{
    margin-right: 20px;
}

.icon:hover{
    opacity:0.5;
}

.icon1{
    margin-right: 20px;
    opacity:0.1;
}



