.container{
    text-align: left;
    padding-top: 10px;
    padding-bottom: 100px;
}

.h4{
    font-size: 17px;
    color:#f3530e;
    margin-bottom: 20px;
}

.aboutText{
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 20px;
}

.cv{
   text-decoration: none;
   color:#878480;
}

.link{
    text-decoration: none;
    color:cadetblue;
}

.link:hover{
    color:#f3530e;
}

.cv:hover .resume{
    color:#f3530e;
}

.cv:hover .arrow{
    color:#f3530e;
    opacity:0;
}

.resume{
    font-size: 14px;
    margin-right: 10px;
    color:#878480;
}

.arrow{
    opacity:0.5;
    animation: spin 4s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

